/**
 * Well, we need to have a way to keep track of the user's story, during the onboarding phase.
 * For that reason, we will need a way to manage this, and thus, we have this class.
 *
 * Ideally, this has been built with state management in mind, hence the naming pattern, so
 * that in future, IF we have NGRX in the project, we will have an easy time transitioning
 * into NGRX. However, for now, we will use Observables pattern.
 *
 * Moreover, it would have been best if we would have this as
 * independent as possible from the underlying framework,
 * Angular, but because we need some dependencies which are
 * part of the Angular Life Cycle for example StorageService,
 * we will have to make this to be part of the
 * Angular Life Cycle.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { OnboardingFeature } from 'src/app/core/domain/feature-attributes.model';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { LOCAL_STORAGE_ONBOARDING } from '../../shared/constants';
import { ONBOARDING_V2 } from '../../shared/constants/feature-flags';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import * as Actions from './actions/actions';
import * as ActionNames from './actions/actions-names';
import { OnboardingStoryGuideState } from './interfaces';
import * as SelectorNames from './selectors/selector-names';
import * as Selectors from './selectors/selectors';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStoryGuideStateManager {
  /**
   * A check to see whether the manage is already initialized for the given user.
   *
   * This is because, it may not be initialized based on the user story, or it may already
   * be initialized
   */
  private _alreadyInitialized: boolean;

  /**
   * This is the state of the onboarding story
   */
  private _onboardingStoryGuideState: OnboardingStoryGuideState;

  /**
   * A hashtable for the actions
   */
  private _actionsMapping: { [actionName: string]: Function };

  /**
   * storage key name
   */
  private _storageKey: string;

  /**
   * A hashtable for the selectors
   */
  private _selectorsMapping: { [selectorName: string]: Observable<any> };

  constructor(
    private _localStorageService: LocalStorageService,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
  ) {
    this._storageKey = LOCAL_STORAGE_ONBOARDING;
  }

  /**
   * Well, this will be the entry point for the application. We will need to have a way to
   * kick-start things off.
   *
   * So, here we will first of check if we have something in storage, because, remember,
   * as at the time of creation of this feature, we are not relying on the backend for
   * any support. So, we will be doing all the synching on the local storage.
   *
   * This is because, the users are actually on an AB test, so we will not have the
   * guarantee that the user will always be picked for the test in subsequent app reloads.
   */
  public async initialize(onboardingFeature: OnboardingFeature) {
    if (this._alreadyInitialized) {
      return;
    }
    this._alreadyInitialized = true;

    /**
     * We fist get the feature flag to check which version of onboarding to show
     */
    const showOnboardingV2 = await firstValueFrom(
      this._getFeatureFlagUsecase.execute(ONBOARDING_V2),
    );
    const activatedOnboardingVersion = this._getOnboardingVersion(showOnboardingV2);

    const loadedState = await this._loadState();
    if (loadedState !== null) {
      /**
       * so, whatever the loaded state, we respect the onboardingFeature property
       * because as of now, we don't have any backend support and users may be able
       * to change the reward type
       */
      loadedState.rewardType = onboardingFeature?.rewardType;

      if (!loadedState.hasCompletedOnboardingStory) {
        if (loadedState.version !== activatedOnboardingVersion) {
          loadedState.currentActiveUserStepIndex = 0;
          loadedState.hasCompletedOnboardingStory = false;
        }
      }

      /**
       * So, here, we will be updating the steps as well as the version of onboarding
       * that is being used, irrespective of whether the user has completed the
       * onboarding or not
       */
      loadedState.userSteps = this._getOnboardingStepsByVersion(activatedOnboardingVersion);
      loadedState.version = activatedOnboardingVersion;

      /**
       * now we write to storage the loaded state, because it may be from the backend
       * in future
       */
      this._createStorageObject(loadedState);
      if (!loadedState.hasCompletedOnboardingStory) {
        /**
         * So, we will only initialize the onboarding state, IFF the user has not completed their onboarding
         * journey.
         */
        this._onboardingStoryGuideState = this._marshallDataToOnboardingStateFormat(loadedState);
        this._hydrateStateOperators();
      }
    } else {
      /**
       * we don't have a loaded user state, so we initialize a fresh one and then we set
       * this to storage, so that it is possible for the user to interact with it
       */
      const freshState = this._instantiateFreshState(
        onboardingFeature,
        this._getOnboardingVersion(showOnboardingV2),
      );
      this._createStorageObject(freshState);
      this._onboardingStoryGuideState = this._marshallDataToOnboardingStateFormat(freshState);
      this._hydrateStateOperators();
    }
  }

  /**
   *
   * @param useOnboardingV2
   *
   * This may seem unneeded, but we may need it down the line, if we have other versions,
   * and in that case we may want to use Feature Attributes instead of Feature Flags
   */
  private _getOnboardingVersion(useOnboardingV2: boolean): string {
    if (useOnboardingV2) {
      return 'v2';
    }
    return 'v1';
  }

  private _getOnboardingStepsByVersion(version: string): Array<number> {
    switch (version) {
      case 'v2':
        return [1, 2, 3, 4];
      default:
        return [1, 2, 3, 4, 5, 6, 7, 8];
    }
  }

  /**
   * We create a storage object, so that we can have a source of truth for recording
   * updates from the UI
   */
  private async _createStorageObject(state: any) {
    this._localStorageService.setStorage(this._storageKey, state);
  }

  /**
   *
   * @returns
   *
   * Check whether the manager is initialized, before listening / making any manager calls
   */
  public managerIsInitialized(): boolean {
    return this._alreadyInitialized && !!this._onboardingStoryGuideState;
  }

  /**
   * Return state on whether the user has started their onboarding journey or not,
   * and this will be determined if the current user's step is not 0, since at step
   * 0, the user has not started their journey at all.
   */
  public showUserOptionToStartTheOnboardingJourney(): boolean {
    const onboardingState = this._localStorageService.getStorage(this._storageKey);
    if (!onboardingState) {
      /**
       * So, having the storage data is completely optional, since the user may not be
       * picked for the onboarding, in this case we return false, since the user should
       * not be shown the onboarding journey.
       *
       * BUT you may wonder, what if the user deletes this from storage? Well, good thing
       * is that this is set by the guards. So, befor user navigates, the guards will do
       * the interception and set these.
       */
      return false;
    }
    return onboardingState.currentActiveUserStepIndex === 0;
  }

  /**
   * A function which will hydrate state operators i.e actions and selectors
   */
  private _hydrateStateOperators(): void {
    this._createActionsMapping();
    this._createSelectorsMapping();
  }

  /**
   * create actions mapping
   */
  private _createActionsMapping(): void {
    this._actionsMapping = {
      [ActionNames.ActionSetActiveStep]: Actions.actionSetActiveStep,
    };
  }

  /**
   * create selectors mapping
   */
  private _createSelectorsMapping(): void {
    this._selectorsMapping = {
      [SelectorNames.SelectCurrentActiveUserStepIndex]: Selectors.selectCurrentActiveUserStepIndex(
        this._onboardingStoryGuideState,
      ),
      [SelectorNames.SelectRewardTypeForUse]: Selectors.selectRewardType(
        this._onboardingStoryGuideState,
      ),
      [SelectorNames.SelectUserOnboardingCompletionStatus]:
        Selectors.selectUserOnboardingCompletionStatus(this._onboardingStoryGuideState),
    };
  }

  /**
   * We will be loading the state of the application maybe from storage or backend, and
   * so that we are safer for possible future proofing, we will have this as an async
   * function.
   */
  private async _loadState(): Promise<any> {
    let existingState: any = await this._loadDataFromStorage();
    if (typeof existingState === 'string' && existingState.startsWith('{')) {
      existingState = JSON.parse(existingState);
    }

    return existingState;
  }

  /**
   * We need to marshall the received state, or a new one we are creating to be compatible with the
   * on boarding story guide state structure
   */
  private _marshallDataToOnboardingStateFormat(state: any): OnboardingStoryGuideState {
    return {
      rewardType: new BehaviorSubject(state.rewardType),
      currentActiveUserStepIndex: new BehaviorSubject(state.currentActiveUserStepIndex),
      hasCompletedOnboardingStory: new BehaviorSubject(state.hasCompletedOnboardingStory),
      userSteps: new BehaviorSubject(state.userSteps),
      onboardingVersion: new BehaviorSubject(state.onboardingVersion),
    };
  }

  /**
   *
   * @param action
   * @param payload
   *
   * receives an action and a payload for the action.
   *
   * For example, an action is 'Set the currentOrderingGoalIndex', and payload is 1.
   *
   * This will target state.orderingGoals.currentOrderingGoalIndex and we set it to 1.
   */
  public updateStatePiece(action: string, payload: any): void {
    if (!this._alreadyInitialized || !this._actionsMapping) {
      return;
    }
    this._actionsMapping[action](
      this._storageKey,
      this._localStorageService,
      this._onboardingStoryGuideState,
      payload,
    );
  }

  /**
   *
   * @param selectorName
   *
   * This method will receive a selector name, and then it will return the observable of that
   * specific UI piece.
   */
  public selectStatePiece(selectorName: string): Observable<any> {
    if (!this._alreadyInitialized || !this._selectorsMapping) {
      return of(null);
    }
    return this._selectorsMapping[selectorName];
  }

  /**
   * We did not have a state, so we will instantiate the state
   *
   * Important things to note here are:
   *
   * 1. rewardType -> We are getting this from the feature attributes
   * 2. currentActiveUserStepIndex -> we are initializing it to 0, because it is a fresh
   * state
   */
  private _instantiateFreshState(onboardingFeature: OnboardingFeature, version: string) {
    return {
      rewardType: onboardingFeature.rewardType,
      currentActiveUserStepIndex: 0,
      hasCompletedOnboardingStory: false,
      userSteps: this._getOnboardingStepsByVersion(version),
      version,
    };
  }

  /**
   *
   * @returns
   *
   * Load data from an external source, this may be local storage
   * or it may be the backend when we have support from there.
   */
  private async _loadDataFromStorage() {
    return new Promise((resolve, reject) => {
      resolve(this._localStorageService.getStorage(this._storageKey));
    });
  }
}
