import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import { navigateTo } from '@presentation/shared/router';
import { combineLatest, Subscription } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { FEATURE_FLAGS, LOGIN_URL, PRE_ORDER_USER_FEATURE } from '../../constants';
import {
  CUSTOM_CHECKOUT_BUTTON,
  NEW_INCENTIVE_BUTTON,
  PRE_ORDER_REQUESTS_BUTTON,
  PROFILE_NAVIGATION_TABS,
  SIGNOUT_BUTTON,
} from '../../constants/header-tabs';
import { HeaderTab } from '../../interfaces/header.interface';
import { featureAttributeAssign } from '../../utilities/feature-attribute-assign.utility';

@Component({
  selector: 'app-account-shared-layout',
  templateUrl: './account-shared-layout.component.html',
  styleUrls: ['./account-shared-layout.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, NgIf, RouterOutlet, TranslateModule],
})
export class AccountSharedLayoutComponent implements OnInit, OnDestroy {
  public navigationButtons: HeaderTab[];

  public currentRoute: string;

  private _currentNavigationButtons: HeaderTab[] = PROFILE_NAVIGATION_TABS;

  public routerEventsSubscription: Subscription;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private router: Router,
    private _logoutUseCase: LogoutUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.updateTabsBasedOnUserFeatures();
    this.setNavigationButtons();
    this.routerEventsSubscription = this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = this.router.url;
          this.setNavigationButtons();
        }
      },
    });
  }

  updateTabsBasedOnUserFeatures(): void {
    combineLatest({
      preOrder: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3),
      newIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.INCENTIVE_PROGRAM_V3),
      ksaIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.KSA_INCENTIVE_PROGRAM),
      customCheckout: this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_CUSTOM_CHECKOUT),
    }).subscribe({
      next: ({ preOrder, newIncentiveProgram, ksaIncentiveProgram, customCheckout }) => {
        if (
          newIncentiveProgram &&
          (country.is('EGY') || (ksaIncentiveProgram && country.is('KSA')))
        ) {
          this._currentNavigationButtons = [
            ...this._currentNavigationButtons.filter(
              (tab) => tab.tabName !== SIGNOUT_BUTTON.tabName,
            ),
            NEW_INCENTIVE_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + country.code.toLowerCase(),
        );
        if (preOrder && userHasPreOrder) {
          this._currentNavigationButtons = [
            ...this._currentNavigationButtons.filter(
              (tab) => tab.tabName !== SIGNOUT_BUTTON.tabName,
            ),
            PRE_ORDER_REQUESTS_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        const shouldShowCustomCheckout = featureAttributeAssign(
          customCheckout,
          user.id,
          country.code,
        );
        if (shouldShowCustomCheckout) {
          this._currentNavigationButtons = [
            ...this._currentNavigationButtons.filter(
              (tab) => tab.tabName !== SIGNOUT_BUTTON.tabName,
            ),
            CUSTOM_CHECKOUT_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        this.filterDuplicateTabs();
        this.navigationButtons = this._currentNavigationButtons;
      },
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
  }

  setNavigationButtons(): void {
    this.navigationButtons = this._currentNavigationButtons.map((navigationButton) => ({
      ...navigationButton,
      iconPath: this.currentRoute.includes(navigationButton.routerLink![0])
        ? navigationButton.iconPath
        : navigationButton.iconPath!.replace('.svg', '-desaturated.svg'),
    }));
  }

  onMenuItemClick(tab: HeaderTab) {
    this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName! });

    if (tab.tabName === SIGNOUT_BUTTON.tabName) {
      this._logoutUseCase.execute();
      navigateTo(LOGIN_URL);
    } else {
      navigateTo(tab.routerLink![0]);
    }
  }

  filterDuplicateTabs(): void {
    const uniqueTabLinks: any = [];
    this._currentNavigationButtons = this._currentNavigationButtons.filter((element) => {
      const isDuplicate = uniqueTabLinks.includes(element.routerLink!);
      if (!isDuplicate) {
        uniqueTabLinks.push(element.routerLink!);
        return true;
      }
      return false;
    });
  }
}
